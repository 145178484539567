import { useEffect, useState } from "react";
import { TerminCard, NoTerminCard } from "./";
import { Header } from "./";

import { Helmet } from "react-helmet";
import { getHomePageTermine } from "../content-graphcms/termine";

const Home = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchTermine() {
      const dataTermine = await getHomePageTermine();
      setData(dataTermine);
    }

    fetchTermine();
  }, []);

  return (
    <>
      <Helmet>
        <title>Startseite</title>
        <meta
          name="description"
          content="Willkommen auf den Seiten des Kulturvereins Küsterscheune Betzendorf. Wir laden Sie ein zu unseren Konzerten, Lesungen, Vorträgen, Sonntagsrunden, Ausstellungen und in die Kinderbücherei. Pressen Sie im Herbst mit uns auch Ihren Apfelsaf!"
        ></meta>
      </Helmet>
      {/* <Announcment /> */}
      <Header />

      <div className=" text-xl font-roboto  text-gray-500 mt-8 ml-4 font-extrabold">
        Termine
      </div>

      <div class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-6 ml-4">
        {data.length === 0 ? (
          <NoTerminCard
            item={{
              title: "Keine Termine geplant",
              description: "Es sind aktuell keine Termine geplant.",
            }}
          />
        ) : (
          <>
            {data.map((item) => {
              return <TerminCard key={item.id} item={item} />;
            })}
          </>
        )}
      </div>
    </>
  );
};

export default Home;
