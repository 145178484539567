import { Header } from "./";
import { useEffect, useState } from "react";
import getBuecherei from "../content-graphcms/buecherei";
import RichTextRender from "../custom-utils/RichText";

const Buecherei = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  useEffect(() => {
    async function fetchBuecherei() {
      const dataBuecherei = await getBuecherei();
      setData(dataBuecherei);
      setLoading(false);
    }
    fetchBuecherei();
  }, []);
  if (loading) {
    return <div>Loading...</div>;
  } else {
    return (
      <div>
        <Header />
        <div className="md:m-4 m-3">
          <div className="md:flex md:justify-center">
            <img
              src={data[0].bild.url}
              alt="Bücherei"
              className="h-full w-full rounded-md object-cover md:max-h-[30rem]  shadow-md shadow-gray-700 "
            />
          </div>
          <div className="font-roboto">
            <h1 className="text-4xl font-extrabold  mt-4 font-roboto text-gray-700">
              {data[0].name}
            </h1>
            <div className="mt-2 text-sm  text-gray-700 ">
              <RichTextRender text={data[0].text.raw} />
            </div>
          </div>

          <div class="rounded-xl bg-gradient-to-r from-green-300 via-blue-500 to-purple-600 p-0.5 shadow-xl transition hover:shadow-sm mt-4">
            <div class="rounded-[10px] bg-white p-4  sm:p-6">
              <h4 className="block text-sm text-gray-500">Öffungszeiten</h4>
              {Object.keys(data[0].offnungszeiten).map((key) => {
                if (data[0].offnungszeiten[key] === "Geschlossen") {
                  return null;
                } else if (key === "ferien") {
                  return (
                    <p className="mt-0.5 text-lg font-roboto  text-gray-900">
                      <span className="font-semibold">
                        {key.charAt(0).toUpperCase() + key.slice(1)}:
                      </span>{" "}
                      {data[0].offnungszeiten[key]}
                    </p>
                  );
                } else {
                  return (
                    <p className="mt-0.5 text-lg font-roboto  text-gray-900">
                      <span className="font-semibold">
                        {key.charAt(0).toUpperCase() + key.slice(1)}:
                      </span>{" "}
                      {data[0].offnungszeiten[key].open} -{" "}
                      {data[0].offnungszeiten[key].close} Uhr
                    </p>
                  );
                }
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Buecherei;
