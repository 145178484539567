import { useState, useEffect } from "react";
import { Header, TerminCard, NoTerminCard } from "./";
const {
  getUpcomingSontagsrunden,
  getUpcomingAusstellungen,
  getUpcomingKonzerte,
  getSonstiges,
  getVergangene,
} = require("../content-graphcms/termine");

const Veranstaltungen = () => {
  const [sonntagsrunden, setSonntagsrunden] = useState([]);
  const [ausstellungen, setAusstellungen] = useState([]);
  const [konzerte, setKonzerte] = useState([]);
  const [sonstiges, setSonstiges] = useState([]);
  const [vergangene, setVergangene] = useState([]);

  useEffect(() => {
    async function data() {
      const Sonntagsrunden = await getUpcomingSontagsrunden();
      setSonntagsrunden(Sonntagsrunden);

      const Ausstellungen = await getUpcomingAusstellungen();
      setAusstellungen(Ausstellungen);

      const Konzerte = await getUpcomingKonzerte();
      setKonzerte(Konzerte);

      const Sonstiges = await getSonstiges();
      setSonstiges(Sonstiges);

      const Vergangene = await getVergangene();
      setVergangene(Vergangene);
    }

    data();
  }, []);

  return (
    <>
      <Header />
      <div className="ml-4 mt-4 font-roboto">
        <h2 className="text-xl font-extrabold text-gray-500">Sonntagsrunden</h2>
        <p className="font-normal text-gray-500 ml-1">
          In den Sonntagsrunden erzählen Bürger unserer Gemeinde etwas über ihr
          Leben, ihre Arbeit, ihre Interessen... Kommen Sie! Es lohnt sich
          immer!
        </p>

        <div class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-3 ml-1">
          {sonntagsrunden.length === 0 ? (
            <div>
              <NoTerminCard
                item={{
                  title: "Keine Sonntagsrunden",
                  description: "Es sind keine Sonntagsrunden geplant.",
                }}
              />
            </div>
          ) : (
            sonntagsrunden.map((item) => {
              return <TerminCard key={item.id} item={item} />;
            })
          )}
        </div>
      </div>
      <div className="ml-4 mt-4 font-roboto">
        <h2 className="text-xl font-extrabold text-gray-500">Ausstellungen</h2>
        <p className="font-normal text-gray-500 ml-1">
          Die ehemalige Küsterscheune eignet sich hervorragend für
          Ausstellungen. Auf zwei Etagen können Kunstwerke präsentiert werden.
          An den weißen Wänden sind überall bereits Galerieleisten angebracht
          und die Beleuchtung ist auf den Ausstellungsbetrieb abgestimmt.
        </p>
        <div class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-3 ml-1">
          {ausstellungen.length === 0 ? (
            <div>
              <NoTerminCard
                item={{
                  title: "Keine Ausstellungen",
                  description: "Es sind aktuell keine Ausstellungen geplant.",
                }}
              />
            </div>
          ) : (
            ausstellungen.map((item) => {
              return <TerminCard key={item.id} item={item} />;
            })
          )}
        </div>
      </div>
      <div className="ml-4 mt-4 font-roboto">
        <h2 className="text-xl font-extrabold text-gray-500">Konzerte</h2>

        <div class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-3 ml-1">
          {konzerte.length === 0 ? (
            <div>
              <NoTerminCard
                item={{
                  title: "Keine Konzerte",
                  description: "Es sind aktuell keine Konzerte geplant.",
                }}
              />
            </div>
          ) : (
            konzerte.map((item) => {
              return <TerminCard key={item.id} item={item} />;
            })
          )}
        </div>
      </div>
      {sonstiges.length > 0 && (
        <div className="ml-4 mt-4 font-roboto">
          <h2 className="text-xl font-extrabold text-gray-500">Sonstiges</h2>
          <div class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-3 ml-1">
            {sonstiges.map((item) => {
              return <TerminCard key={item.id} item={item} />;
            })}
          </div>
        </div>
      )}
      {vergangene.length > 0 && (
        <div className="ml-4 mt-4 font-roboto">
          <h2 className="text-xl font-extrabold text-gray-500">
            Vergangene Veranstaltungen
          </h2>
          <div class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-3 ml-1">
            {vergangene.map((item) => {
              return <TerminCard key={item.id} item={item} />;
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default Veranstaltungen;
