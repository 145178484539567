import { request, gql } from "graphql-request";
const date = new Date(new Date().setHours(0, 0, 0, 0)).toISOString();
async function getHomePageTermine() {
  const query = gql`
    query Termine {
      termine(where: { displayOnHomePage: true, startDate_gt: "${date}"} orderBy: startDate_ASC) {
        color {
          hex
          css
        }
        displayOnHomePage
        description

        slug
        title
        foto {
          url
        }
        endDate
        startDate
      }
    }
  `;

  const data = await request(
    "https://api-eu-central-1-shared-euc1-02.hygraph.com/v2/cla8cjll60koi01t58ghq5id4/master",
    query
  );

  return data.termine;
}

async function getTermin(slug) {
  const query = gql`
  query Termine {
    termine(where: { slug: "${slug}" }) {
      color {
        hex
        
      }
      displayOnHomePage
      description
      langerText {
        raw
        references {
          ... on Termin {
            id
            title
            description
           foto {
              url
            }
            slug
            color {
              hex
            }
          }
        }
      }
      info{
        raw
        references {
          ... on Termin {
            id
            title
            description
             foto {
              url
            }
            slug
            color {
              hex
            }
          }

        }
      }
      
      slug
      title
      foto {
        url
      }
      endDate
      startDate
    }
  }
  `;

  const data = await request(
    "https://api-eu-central-1-shared-euc1-02.hygraph.com/v2/cla8cjll60koi01t58ghq5id4/master",
    query
  );

  return {
    data: data.termine,
    color: data.termine[0].color.hex,
  };
}

async function getLinkedTermine(slug, date, type) {
  const query = gql`
  query Termine {
    termine(where: { slug: "${slug}" }) {
      
          conectedTermine(where: {startDate_gt: "2023-01-22T16:00:00+00:00", veranstalltunsTyp: ${type}}) {
            slug
            title
            foto {
              url
            }
            color {
              hex
            }
            description
          }
        }
        
  }
  `;

  const data = await request(
    "https://api-eu-central-1-shared-euc1-02.hygraph.com/v2/cla8cjll60koi01t58ghq5id4/master",
    query
  );

  return data.termine[0].conectedTermine;
}

async function getUpcomingSontagsrunden() {
  const query = gql`
    query Sonntagsrunden {
      termine(where: { veranstalltunsTyp: Sonntagsrunden, startDate_gt: "${date}" }) {
        color {
          hex
        }
        foto {
          url
        }
        id
        slug
        title
        startDate
        description
      }
    }
  `;

  const data = await request(
    "https://api-eu-central-1-shared-euc1-02.hygraph.com/v2/cla8cjll60koi01t58ghq5id4/master",
    query
  );

  return data.termine;
}

async function getUpcomingAusstellungen() {
  const query = gql`
    query Sonntagsrunden {
      termine(where: { veranstalltunsTyp: Ausstellungen, startDate_gt: "${date}" }) {
        color {
          hex
        }
        foto {
          url
        }
        id
        slug
        title
        startDate
        description
      }
    }
  `;

  const data = await request(
    "https://api-eu-central-1-shared-euc1-02.hygraph.com/v2/cla8cjll60koi01t58ghq5id4/master",
    query
  );

  return data.termine;
}

async function getUpcomingKonzerte() {
  const query = gql`
    query Sonntagsrunden {
      termine(where: { veranstalltunsTyp: Konzerte, startDate_gt: "${date}" }) {
        color {
          hex
        }
        foto {
          url
        }
        id
        slug
        title
        startDate
        description
      }
    }
  `;

  const data = await request(
    "https://api-eu-central-1-shared-euc1-02.hygraph.com/v2/cla8cjll60koi01t58ghq5id4/master",
    query
  );

  return data.termine;
}

async function getSonstiges() {
  const query = gql`
    query Sonntagsrunden {
      termine(where: { veranstalltunsTyp: Sonstiges, startDate_gt: "${date}" }) {
        color {
          hex
        }
        foto {
          url
        }
        id
        slug
        title
        startDate
        description
      }
    }
  `;

  const data = await request(
    "https://api-eu-central-1-shared-euc1-02.hygraph.com/v2/cla8cjll60koi01t58ghq5id4/master",
    query
  );

  return data.termine;
}

async function getVergangene() {
  const query = gql`
    query Sonntagsrunden {
      termine(where: { startDate_lt: "${date}" }) {
        color {
          hex
        }
        foto {
          url
        }
        id
        slug
        title
        startDate
        description
      }
    }
  `;

  const data = await request(
    "https://api-eu-central-1-shared-euc1-02.hygraph.com/v2/cla8cjll60koi01t58ghq5id4/master",
    query
  );

  return data.termine;
}

export {
  getHomePageTermine,
  getTermin,
  getLinkedTermine,
  getUpcomingSontagsrunden,
  getUpcomingAusstellungen,
  getUpcomingKonzerte,
  getSonstiges,
  getVergangene,
};
