import { gql, request } from "graphql-request";

async function getBuecherei() {
  const query = gql`
    query Buecherei {
      buchereis {
        name
        offnungszeiten
        text {
          raw
        }
        bild {
          url
        }
      }
    }
  `;

  const data = await request(
    "https://api-eu-central-1-shared-euc1-02.hygraph.com/v2/cla8cjll60koi01t58ghq5id4/master",
    query
  );

  console.log(data.buchereis);

  return data.buchereis;
}

export default getBuecherei;
