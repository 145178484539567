const AusstellerCard = ({ aussteller, color }) => {
  return (
    <>
      <div class="flex bg-white transition hover:shadow-xl font-roboto">
        <div class="rotate-180 p-2 [writing-mode:_vertical-lr]">
          <div class="flex items-center justify-between gap-4 text-xs font-bold uppercase text-gray-900">
            <span>Station</span>
            <span class="w-px flex-1 bg-gray-900/10"></span>
            <span>{aussteller.stationsnummer}</span>
          </div>
        </div>

        <div class="hidden sm:block sm:basis-56">
          <img
            alt={aussteller.name}
            src={aussteller.foto.url}
            class="aspect-square h-full w-full object-cover"
          />
        </div>

        <div class="flex flex-1 flex-col justify-between">
          <div class="border-l border-gray-900/10 p-4 sm:border-l-transparent sm:p-6">
            <a href={`/aussteller/${aussteller.url}`}>
              <h3 class="font-bold uppercase text-gray-900">
                {aussteller.name}
              </h3>
            </a>

            <p class="mt-2 text-sm leading-relaxed text-gray-700 line-clamp-3">
              {aussteller.langerText.text}
            </p>
          </div>

          <div class="sm:flex sm:items-end sm:justify-end">
            <a
              href={`/aussteller/${aussteller.url}`}
              class="block  px-5 py-3 text-center text-xs font-bold uppercase text-gray-900 transition "
              style={{ backgroundColor: color }}
            >
              Mehr erfahren
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default AusstellerCard;
