import { useParams } from "react-router-dom";
import { Header, TerminCard } from "./";
import { useEffect, useState } from "react";
import { getTermin, getLinkedTermine } from "../content-graphcms/termine";
import RichTextRender from "../custom-utils/RichText";

const Termine = () => {
  const { slug } = useParams();
  const [termin, setTermin] = useState([]);
  const [color, setColor] = useState("#941F1F");
  const [linkedTermine, setLinkedTermine] = useState([]);

  useEffect(() => {
    getTermin(slug).then((data) => {
      setTermin(data.data);
      console.log(data.data);
      setColor(data.color);
      getLinkedTermine(slug, data.data[0].startDate, "Sonntagsrunden").then(
        (data) => {
          setLinkedTermine(data);
        }
      );
    });
  }, [slug]);

  return (
    <div>
      <Header color={color} />
      {termin.map((item) => {
        return (
          <div key={item.title}>
            <div className="m-4 sm:flex grid grid-cols-1 gap-6">
              <img
                alt={item.title}
                src={item.foto[0].url}
                className="h-full w-full rounded-md object-cover md:w-1/2 "
              />
              <article class="flex bg-white shadow-lg  rounded-md p-3">
                <div class="flex flex-1 flex-col justify-between">
                  <div class=" border-gray-900/10 p-4  sm:p-6">
                    <h3 class="font-bold uppercase text-gray-700">
                      Informationen
                    </h3>

                    <div class="mt-2 text-sm  text-gray-700 ">
                      <RichTextRender
                        text={item.info.raw}
                        references={item.info.references}
                        date={item.startDate}
                        terminSite={true}
                      />
                    </div>
                  </div>
                </div>
                <div class="hidden sm:block sm:basis-56 ">
                  <img
                    alt="Guitar"
                    src={item.foto[1].url}
                    class="aspect-square h-full w-full object-cover"
                  />
                </div>
              </article>
            </div>
            <h1 className="text-4xl font-extrabold ml-4 mt-4 font-roboto text-gray-700">
              {item.title}
            </h1>
            <div className=" ml-4 mt-4  font-roboto text-gray-700 font-normal ">
              <div>
                <RichTextRender
                  text={item.langerText.raw}
                  references={item.langerText.references}
                />
              </div>
            </div>
          </div>
        );
      })}
      {linkedTermine.length > 0 && (
        <div>
          <h1 className="text-xl font-bold ml-4 mt-4 font-roboto text-gray-700">
            Weitere Sonntagsrunden
          </h1>
          <div className="flex flex-wrap">
            {linkedTermine.map((item) => {
              return <TerminCard item={item} />;
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Termine;
