import { useEffect, useState } from "react";
import { getDokumente } from "../content-graphcms/dokumente";
import ueberUns from "../content-graphcms/ueber-uns";

import { Header, DokumentCard } from "./";
import RichTextRender from "../custom-utils/RichText";

import { Helmet } from "react-helmet";

const Verein = () => {
  const [dokumente, setDokumente] = useState([]);
  const [dataUeberUns, setDataUeberUns] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function data() {
      const Dokumente = await getDokumente();
      async function fetchUeberUns() {
        const dataUeberUns = await ueberUns();

        setDataUeberUns(dataUeberUns);
      }
      fetchUeberUns();
      setDokumente(Dokumente);
      setLoading(false);
    }

    data();
  }, []);
  if (loading === false) {
    return (
      <>
        <Helmet>
          <title>Verein</title>
          <meta name="description" content="Verein" />
        </Helmet>
        <Header />
        <div className="md:m-4 m-3">
          <div className="md:flex md:justify-center">
            {dataUeberUns.map((item) => {
              return (
                <img
                  src={item.vorstand.url}
                  alt="Vorstand"
                  className="h-full w-full rounded-md object-cover md:max-h-[40rem]  shadow-md shadow-gray-500 "
                />
              );
            })}
          </div>
        </div>

        <div className="m-3 md:m-4 font-roboto text-gray-500">
          <h2 className=" text-gray-700 font-bold text-2xl ">Über uns</h2>
          <div className="font-roboto font-normal  text-gray-500 mt-2 ml-1 ">
            {dataUeberUns.map((item) => {
              return (
                <RichTextRender
                  key={item.id}
                  text={item.text.raw}
                  references={item.text.references}
                />
              );
            })}
          </div>

          <h2 className="text-gray-700 font-bold text-2xl mt-2">Downloads</h2>
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-2">
            {dokumente.map((dokument) => (
              <DokumentCard
                key={dokument.id}
                title={dokument.titel}
                description={dokument.beschreibung}
                file={dokument.dokument.url}
              />
            ))}
          </div>
          <h2 className="text-gray-700 font-bold text-2xl mt-2 ">Kontakt</h2>
          <div className="font-roboto font-normal  text-gray-500 mt-2 sm:flex block ml-1 ">
            <div className="">
              {dataUeberUns.map((item) => {
                return <RichTextRender key={item.id} text={item.kontakt.raw} />;
              })}
            </div>

            <div className="mb-4 block rounded-lg p-4 shadow-md shadow-indigo-100 hover:shadow-lg sm:ml-16 ">
              <iframe
                className="sm:w-[600px] sm:h-[350px] w-full h-[250px]"
                title="Karte"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4787.013004346617!2d10.309886065641212!3d53.13700387803927!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b1da8d5c646ac3%3A0xdb90a136f2b50829!2sKulturverein%20K%C3%BCsterscheune%20Betzendorf%20e.V.!5e0!3m2!1sde!2sde!4v1673177977864!5m2!1sde!2sde"
                allowfullscreen="true"
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default Verein;
