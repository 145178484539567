import { request, gql } from "graphql-request";

async function getAnnouncements() {
  const query = gql`
    query Announcement {
      ankundigungen {
        title
        ankundigung {
          ... on Termin {
            id
            slug
          }
        }
      }
    }
  `;

  const data = await request(
    "https://api-eu-central-1-shared-euc1-02.hygraph.com/v2/cla8cjll60koi01t58ghq5id4/master",
    query
  );

  return data.ankundigungen;
}

export default getAnnouncements;
