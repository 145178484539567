const { gql, request } = require("graphql-request");

async function getApfelsaftpressen() {
  const query = gql`
    query Apfelsaftpressen {
      apfelsaftpressens {
        beschreibung {
          raw
        }
        termine {
          raw
        }
        foto {
          url
        }
      }
    }
  `;

  const data = await request(
    "https://api-eu-central-1-shared-euc1-02.hygraph.com/v2/cla8cjll60koi01t58ghq5id4/master",
    query
  );

  return data.apfelsaftpressens;
}

export default getApfelsaftpressen;
