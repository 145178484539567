import { gql, request } from "graphql-request";

const endpoint =
  "https://api-eu-central-1-shared-euc1-02.hygraph.com/v2/cla8cjll60koi01t58ghq5id4/master";

async function getKulturstationen() {
  const query = gql`
    query Kulturstationen {
      kulturstationens {
        aussteller {
          ... on Aussteller {
            name
            ausstellerTyp
            id
            stationsnummer
            url
            langerText {
              text
            }
            beschreibung
            foto {
              url
            }
          }
        }
        farbe {
          hex
        }
        startDate
        titel
        foto {
          url
        }
        flyer {
          url
        }
        karte {
          url
        }
        description {
          raw
        }
        endDate
        id
      }
    }
  `;
  const data = await request(endpoint, query);
  console.log(data.kulturstationens[0]);
  return {
    data: data.kulturstationens[0],
    color: data.kulturstationens[0].farbe.hex,
  };
}

async function getAussteller(slug) {
  const query = gql`
    query Aussteller {
      
        aussteller(where: {url: "${slug}"}) {
          gastaussteller {
            langerText {
              text
            }
            id
            name
            stationsnummer
            url
            foto {
              url
            }
          }
          id
          name
          foto {
            url
          }
          adresse
          information {
            raw
          }
          stationsnummer
          website
          langerText {
            raw
            references {
              ... on Aussteller {
                id
                name
                url
                stationsnummer
                langerText {
                  text
                }
                foto {
                  url
                }
              }
            }
          }
          kulturstationen {
            farbe {
              hex
            }
          }
        }
      
      
      
    }
  `;
  const data = await request(endpoint, query);
  console.log(data.aussteller);
  return {
    data: data.aussteller,
    color: data.aussteller.kulturstationen[0].farbe.hex,
  };
}

export { getKulturstationen, getAussteller };
