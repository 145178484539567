import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { getAussteller } from "../content-graphcms/kulturstationen";
import { Header, AusstellerCard } from "./";
import RichTextRender from "../custom-utils/RichText";

const Aussteller = () => {
  const { slug } = useParams();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [color, setColor] = useState("#941F1F");

  useEffect(() => {
    getAussteller(slug).then((data) => {
      setData(data.data);

      setLoading(false);
    });
  }, [slug]);

  if (slug === undefined) {
    return (window.location.href = "/404");
  }

  if (loading === false) {
    return (
      <>
        <Helmet>
          <title>{data.name} - Kulturstationen</title>
          <meta name="description" content={data.name} />
        </Helmet>
        <Header color={color} />
        <div>
          <div className="m-3 md:m-4 font-roboto">
            <div className="md:flex md:justify-center">
              <img
                src={data.foto.url}
                alt={data.name}
                className=" w-full h-full rounded-md object-cover  md:max-h-[30rem]  shadow-md shadow-gray-400 "
              />
            </div>
            <div className="font-roboto grid grid-cols-1 sm:grid-cols-2 sm:gap-5">
              <div className="mb-4 block rounded-lg p-4 shadow-md shadow-indigo-100 hover:shadow-lg">
                <div className="text-2xl  font-bold text-gray-700 mt-4 ">
                  <h1>{data.name}</h1>
                </div>
                <div className="mt-2">
                  <RichTextRender key={data.id} text={data.langerText.raw} />
                </div>
              </div>
              <div className="mb-4 block rounded-lg p-4 shadow-md shadow-indigo-100 hover:shadow-lg">
                <div className="text-2xl  font-bold text-gray-700 mt-4 ">
                  <h1>Informationen</h1>
                </div>
                <div className="mt-2 text-gray-500">
                  <span>
                    <b>Station:</b> {data.stationsnummer}
                  </span>
                  <br />

                  <span>
                    <b>Adresse:</b> {data.adresse}
                  </span>
                  {data.information && (
                    <div className="mt-2">
                      <h2 className="font-bold">Weitere Informationen:</h2>
                      <RichTextRender text={data.information.raw} />
                    </div>
                  )}
                  <br />
                  {data.website && (
                    <a
                      class="group relative inline-flex items-center overflow-hidden rounded  px-8 py-3 text-white focus:outline-none focus:ring mt-3 "
                      href={data.website}
                      style={{ backgroundColor: color }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span class="absolute right-0 translate-x-full transition-transform group-hover:-translate-x-4">
                        <svg
                          class="h-5 w-5"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M17 8l4 4m0 0l-4 4m4-4H3"
                          />
                        </svg>
                      </span>

                      <span class="text-sm font-medium transition-all group-hover:mr-4">
                        Website
                      </span>
                    </a>
                  )}
                </div>
              </div>
            </div>
            {data.gastaussteller.length > 0 && (
              <div>
                <div className="text-2xl  font-bold text-gray-700 mt-4 ">
                  <h1>Gastaussteller</h1>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-3 ">
                  {data.gastaussteller.map((item) => {
                    return (
                      <AusstellerCard
                        key={item.id}
                        aussteller={item}
                        color={color}
                      />
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
};

export default Aussteller;
