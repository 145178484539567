import { request, gql } from "graphql-request";

export async function getDokumente() {
  const query = gql`
    query Dokumente {
      dokumentes {
        titel
        beschreibung
        id
        dokument {
          url
        }
      }
    }
  `;

  const data = await request(
    "https://api-eu-central-1-shared-euc1-02.hygraph.com/v2/cla8cjll60koi01t58ghq5id4/master",
    query
  );
  return data.dokumentes;
}
