const DokumentCard = ({ title, description, file }) => {
  return (
    <div>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {title}
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">{description}</p>
          <a
            class="group relative inline-flex items-center overflow-hidden rounded  px-8 py-3 text-white focus:outline-none focus:ring mt-2 ml-1 bg-v-red"
            href={file}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span class="absolute left-0 -translate-x-full transition-transform group-hover:translate-x-4">
              <svg
                class="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </svg>
            </span>

            <span class="text-sm font-medium transition-all group-hover:ml-4">
              Download
            </span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default DokumentCard;
