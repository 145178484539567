import { useState, useEffect } from "react";
import getApfelsaft from "../content-graphcms/apfelsaftpressen";
import { Header } from "./";
import RichTextRender from "../custom-utils/RichText";
const Apfelsaftpressen = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    async function apfelsaft() {
      const dataApfelsaft = await getApfelsaft();
      setData(dataApfelsaft);
    }
    apfelsaft();
  }, []);

  return (
    <>
      <Header />
      <div>
        {data.map((item) => {
          return (
            <div className="md:m-4 m-3">
              <div className="md:flex md:justify-center">
                <img
                  src={item.foto.url}
                  alt="Bücherei"
                  className="h-full w-full rounded-md object-cover md:max-h-[30rem]  shadow-md shadow-gray-400 "
                />
              </div>

              <div className="font-roboto">
                <h1 className="text-2xl  font-extrabold text-gray-500 mt-4 ">
                  Apfelsaftpressen
                </h1>
              </div>

              <div className="font-roboto font-normal  text-gray-500 mt-2">
                <RichTextRender key={item.id} text={item.beschreibung.raw} />
              </div>
              <div className="font-roboto">
                <h2 className="text-xl  font-extrabold text-gray-500 mt-4 ">
                  Termine
                </h2>
              </div>
              <div className="font-roboto font-normal  ">
                <RichTextRender key={item.id} text={item.termine.raw} />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Apfelsaftpressen;
