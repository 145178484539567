import { useState, useEffect } from "react";
import { getKulturstationen } from "../content-graphcms/kulturstationen";
import { Header, AusstellerCard } from "./";
import RichTextRender from "../custom-utils/RichText";
import { Helmet } from "react-helmet";

const Kulturstationen = () => {
  const [data, setData] = useState({});
  const [color, setColor] = useState("#941F1F");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchKulturstationen() {
      const dataKulturstationen = await getKulturstationen();
      setData(dataKulturstationen.data);

      setLoading(false);
    }
    fetchKulturstationen();
  }, []);

  if (loading === false) {
    return (
      <>
        <Helmet>
          <title>{data.titel}</title>
          <meta name="description" content="Kulturstationen" />
        </Helmet>
        <Header color={color} />
        <div className="m-3 md:m-4 font-roboto">
          <div className="md:flex md:justify-center">
            <img
              src={data.foto.url}
              alt="Kulturstationen"
              className="h-full w-full rounded-md object-cover md:max-h-[30rem] shadow-md shadow-gray-400 "
            />
          </div>
          <div className="text-2xl  font-bold text-gray-500 mt-4 ">
            <h1>{data.titel}</h1>
          </div>
          <div className="font-roboto font-normal  text-gray-500 mt-2">
            <RichTextRender key={data.id} text={data.description.raw} />
          </div>
          <div className="text-2xl  font-bold text-gray-500 mt-4 ">
            <h2>Flyer</h2>
          </div>
          <div className="font-roboto font-normal  text-gray-500 mt-2 ml-1">
            Laden Sie hier den Flyer für die Kulturstationen herunter:
          </div>
          <a
            class="group relative inline-flex items-center overflow-hidden rounded  px-8 py-3 text-white focus:outline-none focus:ring mt-2 ml-1"
            style={{ backgroundColor: color }}
            href={data.flyer.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span class="absolute left-0 -translate-x-full transition-transform group-hover:translate-x-4">
              <svg
                class="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </svg>
            </span>

            <span class="text-sm font-medium transition-all group-hover:ml-4">
              Download
            </span>
          </a>
          <div className="text-2xl font-bold text-gray-500 mt-4 ">
            <h2>Aussteller</h2>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-3 ">
            {data.aussteller.map((item) => {
              return (
                <AusstellerCard key={item.id} aussteller={item} color={color} />
              );
            })}
          </div>
        </div>
      </>
    );
  }
};

export default Kulturstationen;
