import { request, gql } from "graphql-request";

async function getUeberUns() {
  const query = gql`
    query UeberUns {
      ueber_uns {
        text {
          raw
          references {
            ... on Termin {
              id
              title
              slug
              description
            }
          }
        }
        kontakt {
          raw
        }
        vorstand {
          url
        }
      }
    }
  `;

  const data = await request(
    "https://api-eu-central-1-shared-euc1-02.hygraph.com/v2/cla8cjll60koi01t58ghq5id4/master",
    query
  );

  return data.ueber_uns;
}

export default getUeberUns;
