import { RichText } from "@graphcms/rich-text-react-renderer";
import moment from "moment/moment";
import { TerminCard } from "../components";

async function handleClickEvent(slug, openInNewTab) {
  if (openInNewTab) {
    window.open(slug, "_blank");
  } else {
    window.location.href = slug;
  }
}

function RichTextRender({ text, references, date, terminSite = false }) {
  if (terminSite) {
    return (
      <RichText
        content={text}
        references={references}
        renderers={{
          p: ({ children }) => {
            if (
              children.props.content.length >= 2 &&
              children.props.content[1].text.split(" ").includes("{datum}")
            ) {
              return (
                <div className="text-gray-500 flex space-x-1 mt-3">
                  <p className="font-bold">{children.props.content[0].text}</p>
                  <p>
                    {children.props.content[1].text.replace(
                      "{datum}",
                      moment(date).format("DD.MM.YYYY")
                    )}
                  </p>
                </div>
              );
            } else if (
              children.props.content.length >= 2 &&
              children.props.content[1].text.split(" ").includes("{time}")
            ) {
              return (
                <div className="text-gray-500 flex space-x-1 mt-3">
                  <p className="font-bold">{children.props.content[0].text}</p>
                  <p>
                    {children.props.content[1].text.replace(
                      "{time}",
                      moment(date).format("HH:mm")
                    )}
                  </p>
                  <p>Uhr</p>
                </div>
              );
            } else {
              return <p className="text-gray-500 mt-3">{children}</p>;
            }
          },
          ul: ({ children }) => <ul className="list-disc">{children}</ul>,
          ol: ({ children }) => <ol className="list-decimal">{children}</ol>,
          li: ({ children }) => <li className="text-gray-500">{children}</li>,
          blockquote: ({ children }) => (
            <div className="mt-2 mb-2 ml-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                viewBox="0 0 24 24"
              >
                <g>
                  <path
                    d="M8,4H4A4,4,0,0,0,0,8v4a2,2,0,0,0,2,2H7.91A6.006,6.006,0,0,1,2,19a1,1,0,0,0,0,2,8.009,8.009,0,0,0,8-8V6A2,2,0,0,0,8,4Z"
                    fill="#7a7a7a"
                    data-original="#000000"
                  ></path>
                  <path
                    d="M22,4H18a4,4,0,0,0-4,4v4a2,2,0,0,0,2,2h5.91A6.006,6.006,0,0,1,16,19a1,1,0,0,0,0,2,8.009,8.009,0,0,0,8-8V6A2,2,0,0,0,22,4Z"
                    fill="#7a7a7a"
                    data-original="#000000"
                  ></path>
                </g>
              </svg>
              <blockquote className="text-gray-600 ">{children}</blockquote>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                viewBox="0 0 24 24"
              >
                <g>
                  <path
                    d="M8,4H4A4,4,0,0,0,0,8v4a2,2,0,0,0,2,2H7.91A6.006,6.006,0,0,1,2,19a1,1,0,0,0,0,2,8.009,8.009,0,0,0,8-8V6A2,2,0,0,0,8,4Z"
                    fill="#7a7a7a"
                    data-original="#000000"
                  ></path>
                  <path
                    d="M22,4H18a4,4,0,0,0-4,4v4a2,2,0,0,0,2,2h5.91A6.006,6.006,0,0,1,16,19a1,1,0,0,0,0,2,8.009,8.009,0,0,0,8-8V6A2,2,0,0,0,22,4Z"
                    fill="#7a7a7a"
                    data-original="#000000"
                  ></path>
                </g>
              </svg>
            </div>
          ),
          a: ({ href, children, id, openInNewTab }) => (
            <a
              href={href}
              id={id}
              onClick={() => handleClickEvent(href, openInNewTab)}
            >
              <div className="text-v-red hover:underline">{children}</div>
            </a>
          ),
          embed: {
            Termin: ({ title, description, color, slug, foto }) => {
              const item = {
                title: title,
                description: description,
                color: color,
                slug: slug,
                foto: foto,
              };
              return (
                <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-6">
                  <TerminCard item={item} />
                </div>
              );
            },
          },
        }}
      />
    );
  } else {
    return (
      <RichText
        content={text}
        references={references}
        renderers={{
          p: ({ children }) => (
            <p className="text-gray-500  font-roboto font-normal">{children}</p>
          ),
          ul: ({ children }) => <ul className="list-disc">{children}</ul>,
          ol: ({ children }) => <ol className="list-decimal">{children}</ol>,
          li: ({ children }) => <li className="text-gray-500">{children}</li>,
          blockquote: ({ children }) => (
            <div className="mt-2 mb-2 ml-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                viewBox="0 0 24 24"
              >
                <g>
                  <path
                    d="M8,4H4A4,4,0,0,0,0,8v4a2,2,0,0,0,2,2H7.91A6.006,6.006,0,0,1,2,19a1,1,0,0,0,0,2,8.009,8.009,0,0,0,8-8V6A2,2,0,0,0,8,4Z"
                    fill="#7a7a7a"
                    data-original="#000000"
                  ></path>
                  <path
                    d="M22,4H18a4,4,0,0,0-4,4v4a2,2,0,0,0,2,2h5.91A6.006,6.006,0,0,1,16,19a1,1,0,0,0,0,2,8.009,8.009,0,0,0,8-8V6A2,2,0,0,0,22,4Z"
                    fill="#7a7a7a"
                    data-original="#000000"
                  ></path>
                </g>
              </svg>
              <blockquote className="text-gray-600 ">{children}</blockquote>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                viewBox="0 0 24 24"
              >
                <g>
                  <path
                    d="M8,4H4A4,4,0,0,0,0,8v4a2,2,0,0,0,2,2H7.91A6.006,6.006,0,0,1,2,19a1,1,0,0,0,0,2,8.009,8.009,0,0,0,8-8V6A2,2,0,0,0,8,4Z"
                    fill="#7a7a7a"
                    data-original="#000000"
                  ></path>
                  <path
                    d="M22,4H18a4,4,0,0,0-4,4v4a2,2,0,0,0,2,2h5.91A6.006,6.006,0,0,1,16,19a1,1,0,0,0,0,2,8.009,8.009,0,0,0,8-8V6A2,2,0,0,0,22,4Z"
                    fill="#7a7a7a"
                    data-original="#000000"
                  ></path>
                </g>
              </svg>
            </div>
          ),
          a: ({ href, children, id, openInNewTab }) => (
            <a href={href} id={id}>
              <div className="text-v-red hover:underline">{children}</div>
            </a>
          ),

          embed: {
            Termin: ({ title, description, color, slug, foto }) => {
              const item = { title, description, color, slug, foto };
              return <TerminCard item={item} />;
            },
          },
        }}
      />
    );
  }
}

export default RichTextRender;
