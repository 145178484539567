import "./index.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  Home,
  Termine,
  Buecherei,
  Error,
  Veranstaltungen,
  Apfelsaftpressen,
  Kulturstationen,
  Aussteller,
  Verein,
} from "./components";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/termine/:slug" element={<Termine />} />
        <Route path="/buecherei" element={<Buecherei />} />
        <Route path="/apfelsaftpressen" element={<Apfelsaftpressen />} />
        <Route path="/veranstaltungen" element={<Veranstaltungen />} />
        <Route path="/kulturstationen" element={<Kulturstationen />} />
        <Route path="/aussteller/:slug" element={<Aussteller />} />
        <Route path="/verein" element={<Verein />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
